<template>
  <sims-layout>
    <create-announcement-modal
      :visible="createAnnouncementModalVisible"
      @close-modal="closeCreateAnnouncementModal"
    ></create-announcement-modal>

    <div class="sims-lockdowns">
      <!-- Org selector -->
      <div v-if="!lockdownStarted" class="card">
        <div class="header" @click.prevent="showSettings = !showSettings">
          <div class="left">
            <h3>Settings</h3>
          </div>
          <div class="right"></div>
        </div>
        <div class="body" style="padding: 15px 15px 5px" v-show="showSettings">
          <organisation-selector></organisation-selector>
          <h3>Incident</h3>
          <a-form-item label="Incident Type">
            <a-select v-model="selectedIncidentTypeId" style="width: 100%">
              <a-select-option
                v-for="type in sosIncidentTypes"
                :key="type.id"
                :value="type.id"
              >
                {{ type.displayName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <h3>Announcement</h3>
          <div>
            <!-- Group selector -->
            <div>
              <!-- Urgent -->
              <div class="urgent-wrapper">
                <span class="urgent-label">Urgent</span>
                <a-switch v-model="urgent" />
              </div>
              <!-- / Urgent -->
              <a-form-item label="Group" style="margin-bottom: 7px">
                <a-select
                  v-model="selectedGroupId"
                  :disabled="isLoadingGroups"
                  :loading="isLoadingGroups"
                  style="width: 100%"
                  class="group-selector"
                >
                  <a-select-option
                    v-for="group in groupDropdownOptions"
                    :value="group.id"
                    :key="group.id"
                  >
                    {{ group.displayName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </div>
            <!-- / Group selector -->

            <!-- Announcement selector -->
            <div style="margin-top: 5px">
              <a-form-item label="Announcement Type" style="margin-bottom: 7px">
                <a-select
                  v-model="announcementTypeName"
                  :disabled="isLoadingAnnouncementPresets"
                  :loading="isLoadingAnnouncementPresets"
                  style="width: 100%"
                  class="group-selector"
                >
                  <a-select-option
                    v-for="preset in announcementPresets"
                    :value="preset.displayName"
                    option-label-prop="displayName"
                    :key="preset.id"
                  >
                    {{ preset.displayName }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-alert
                v-if="announcementTypeDescription"
                :message="announcementTypeDescription"
                type="info"
                show-icon
              />
            </div>
            <!-- / Announcement selector -->
            <!-- Guidance selector -->
            <select-guidance-modal
              v-if="selectedOrganisation"
              :tenant-id="tenantIdForSelectedOrg"
              :org-id="selectedOrganisation.id"
              @set-guidance="setGuidance"
              :visible="selectGuidanceModalVisible"
              @close-modal="closeSelectGuidanceModal"
            ></select-guidance-modal>

            <div v-if="isSharingGuidance" style="margin-top: 5px">
              <a-form-item label="Guidance" style="margin-bottom: 7px">
                <div class="guidance-pack-selector">
                  <a-button @click.prevent="openSelectGuidanceModal">
                    {{
                      selectedGuidancePackId
                        ? "Change guidance"
                        : "Select guidance"
                    }}
                  </a-button>
                </div>
              </a-form-item>
            </div>
            <!-- / Guidance selector -->
          </div>
        </div>
      </div>
      <!-- / Org selector -->

      <!-- Start Lockdown -->
      <div v-if="!lockdownStarted" class="card">
        <div class="header">
          <h3>Call For Help</h3>
        </div>
        <div class="body bb">
          <div class="flex">
            <div class="left">
              <a-icon
                type="info-circle"
                theme="filled"
                style="font-size: 28px; color: #3298cb"
              ></a-icon>
            </div>
            <div class="right">
              <div>Call for help in case of an emergency</div>
            </div>
          </div>
        </div>
        <div class="body">
          <!-- <a-checkbox v-model="lockDesktops">Lock desktops</a-checkbox> -->

          <div class="form-inner">
            <a-form layout="vertical">
              <a-form-item label="Title to show on screen">
                <a-input v-model="form.title"></a-input>
              </a-form-item>
            </a-form>
          </div>

          <div class="form-inner">
            <a-form layout="vertical">
              <a-form-item label="Message to show on screen">
                <a-textarea v-model="form.message"></a-textarea>
              </a-form-item>
            </a-form>
          </div>

          <div class="form-inner">
            <a-checkbox v-model="sendAnnouncement"
              >Send announcement to staff</a-checkbox
            >
          </div>

          <div class="form-inner form-last">
            <a-button type="primary" @click.prevent="startLockdown"
              >Start Alert</a-button
            >
          </div>
        </div>
      </div>
      <!-- / Start Lockdown -->

      <!-- Stop Lockdown -->
      <div v-if="lockdownStarted" class="card">
        <div class="header">
          <h3>Alert In Progress</h3>
        </div>
        <div class="body bb">
          <div class="flex">
            <div class="left">
              <a-icon
                type="alert"
                theme="filled"
                style="font-size: 28px; color: #3298cb"
              ></a-icon>
            </div>
            <div class="right">
              <div>Alert started {{ lockdownStartedAt.fromNow() }}</div>
            </div>
          </div>
        </div>
        <div class="body">
          <a-descriptions bordered :column="1">
            <!-- <a-descriptions-item label="Lock Desktops">
              <a-icon
                v-if="lockDesktops"
                type="check-circle"
                style="font-size: 25px; color: #52c41a"
              />
              <a-icon
                v-else
                type="close-circle"
                style="font-size: 25px; color: #ff0000"
              />
            </a-descriptions-item> -->
            <a-descriptions-item label="Title to show on screen">
              {{ form.title }}
            </a-descriptions-item>
            <a-descriptions-item label="Message to show on screen">
              {{ form.message }}
            </a-descriptions-item>
            <a-descriptions-item label="Send Announcement">
              <a-icon
                v-if="sendAnnouncement"
                type="check-circle"
                style="font-size: 25px; color: #52c41a"
              />
              <a-icon
                v-else
                type="close-circle"
                style="font-size: 25px; color: #ff0000"
              />
            </a-descriptions-item>
          </a-descriptions>
          <div class="form-inner form-last">
            <a-button type="primary" @click.prevent="stopLockdown"
              >Stop Alert</a-button
            >
          </div>
        </div>
      </div>
      <!-- / Stop Lockdown -->
    </div>
  </sims-layout>
</template>

<script>
import simsLayout from "./simsLayout.vue";
import CreateAnnouncementModal from "../../components/Senso/Announcements/CreateAnnouncementModal.vue";
import moment from "moment";
const _ = require("lodash");
import OrganisationSelector from "../CallForHelp/OrganisationSelector.vue";
import { mapActions, mapGetters } from "vuex";
import chats from "../../api/chats";
// import timelines from "../../api/chats";
import announcements from "../../api/announcements";
import timelines from "../../api/timelines";
import organisationHelpers from "../../helpers/organisations";
import SelectGuidanceModal from "../../components/Announcements/SelectGuidanceModal.vue";
export default {
  components: {
    simsLayout,
    CreateAnnouncementModal,
    OrganisationSelector,
    SelectGuidanceModal,
  },
  data() {
    return {
      lockDesktops: true,
      sendAnnouncement: false,
      form: {
        title: "",
        message: "",
      },
      lockdownStarted: false,
      lockdownStartedAt: null,

      selectedIncidentTypeId: null,

      createAnnouncementModalVisible: false,
      showSettings: false,

      // Announcements
      isLoadingGroups: false,
      groups: [],

      announcementTypeName: null,

      isLoadingGroupMembers: false,
      groupMembers: [],

      isLoadingAnnouncementPresets: false,
      announcementPresets: [],

      selectedGroupId: null,

      selectedGuidancePackId: null,
      selectedContentId: null,
      selectGuidanceModalVisible: null,
      urgent: false,
    };
  },
  watch: {
    selectedOrganisation() {
      this.reloadAnnouncementStuff();
    },
    selectedGroupId(newVal) {
      this.groupMembers = [];
      if (newVal) {
        this.announcementLoadGroupMembers();
      }
    },
  },
  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("helpCaller", {
      isLoadingIncidentTypes: "isLoadingIncidentTypes",
      sosIncidentTypes: "sosIncidentTypes",
      isDemo: "isDemo",
      selectedOrganisation: "selectedOrganisation",
    }),

    ...mapGetters("guidance", {
      guidancePacks: "guidancePacks",
    }),

    tenantIdForSelectedOrg() {
      return this.getOrgTenantId(this.selectedOrganisation);
    },

    selectedIncidentType() {
      let vm = this;
      if (this.selectedIncidentTypeId) {
        return _.find(this.sosIncidentTypes, function (incidentType) {
          return incidentType.id == vm.selectedIncidentTypeId;
        });
      }
      return null;
    },

    canAnnounce() {
      if (this.isSharingGuidance && !this.selectedGuidancePackId) {
        return false;
      }
      return this.selectedGroupId !== null && this.announcementTypeId !== null;
    },

    selectedAnnouncementType() {
      return _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });
    },

    choicesFormatted() {
      return _.map(this.selectedAnnouncementType.choices, "name").join(" or ");
    },

    isSharingGuidance() {
      return this.announcementTypeName == "Share Guidance to Staff";
    },

    announcementTypeId() {
      let announcementType = _.find(this.announcementPresets, {
        displayName: this.announcementTypeName,
      });
      return announcementType ? announcementType.id : null;
    },

    announcementTypeDescription() {
      if (this.announcementTypeId) {
        let announcementType = _.find(this.announcementPresets, {
          displayName: this.announcementTypeName,
        });
        return announcementType.description;
      }
      return null;
    },

    groupDropdownOptions() {
      let options = _.map(this.groups, (group) => {
        return {
          id: group["id"],
          displayName: group["displayName"],
        };
      });
      return _.orderBy(options, "displayName");
    },

    myPresenceId() {
      let myPresenceIds = _.map(this.presences, "id");
      let presenceIdsInOrganisation = _.map(this.groupMembers, "presenceId");
      let intersection = _.intersection(
        myPresenceIds,
        presenceIdsInOrganisation
      );
      if (intersection.length) {
        return _.first(intersection);
      }
      return null;
    },
  },
  methods: {
    ...mapActions("helpCaller", {
      callForHelp: "callForHelp",
    }),
    getOrgTenantId(org) {
      return organisationHelpers.getOrganisationTenantId(org);
    },
    closeCreateAnnouncementModal() {
      this.createAnnouncementModalVisible = false;
    },
    setGuidance(params) {
      let { guidancePackId, contentId, guidancePackName, contentName } = params;
      this.selectedGuidancePackId = guidancePackId;
      this.selectedContentId = contentId;

      if (contentName) {
        this.title = "Guidance: " + contentName;
      } else {
        this.title = "Guidance: " + guidancePackName;
      }

      this.description =
        'Please review this new or updated content in the "' +
        guidancePackName +
        '" guidance pack';

      this.selectGuidanceModalVisible = false;
    },
    startLockdown() {
      let vm = this;
      if (vm.form.title.trim().length == 0) {
        vm.$message.error("Please provide a title to show on screen");
        return false;
      }
      if (vm.form.message.trim().length == 0) {
        vm.$message.error("Please provide a message to show on screen");
        return false;
      }
      vm.lockdownStarted = true;
      vm.lockdownStartedAt = moment();

      if (vm.selectedIncidentTypeId) {
        this.callForHelp({
          description: "Lockdown",
          incidentType: this.selectedIncidentType,
          isDemo: this.isDemo,
          dontNavigate: true,
        });
      }

      if (vm.sendAnnouncement) {
        vm.attemptCreateAnnouncement();
      }
    },

    attemptCreateAnnouncement() {
      // this.createAnnouncementModalVisible = true;
      // this.$message.success('Announcement created');
      let vm = this;
      if (this.canAnnounce) {
        let tenantId = organisationHelpers.getOrganisationTenantId(
          this.selectedOrganisation
        );

        let announcementType = _.find(this.announcementPresets, {
          displayName: this.announcementTypeName,
        });

        let announcementRequest = {
          OwnerId: this.selectedOrganisation.id,
          CreatedBy: this.myPresenceId,
          DisplayName: this.form.title,
          Choices: announcementType.choices,
          Urgent: this.urgent,
        };

        announcements
          .addAnnouncement(tenantId, announcementRequest)
          .then((r) => {
            // console.log(r);
            // vm.setExpectedNumberOfGroupMembers({
            //   announcementId: r.data.id,
            //   expectedNumberOfGroupMembers: this.groupMembers.length,
            // });
            vm.postTimelineEntry(tenantId, r.data);
          })
          .catch((e) => {
            console.log(e);
            vm.isSaving = false;
            vm.$message.error("Error creating announcement");
          });
      }
    },

    postTimelineEntry(tenantId, addAnnouncementResponse) {
      let vm = this;

      let timelineMessage;
      if (this.isSharingGuidance) {
        let dollarV = {
          Text: vm.form.message,
          GuidancePackId: vm.selectedGuidancePackId,
        };
        if (vm.selectedContentId) {
          dollarV["ContentId"] = vm.selectedContentId;
        }

        timelineMessage = {
          OwnerType: 3,
          OwnerId: addAnnouncementResponse.id,
          Behaviour: 1,
          CreatedByPresenceId: this.myPresenceId,
          Content: {
            $t: "Soteria.Models.GuidanceChatEntry, Soteria.Models",
            $v: dollarV,
          },
        };
      } else {
        timelineMessage = {
          OwnerType: 3,
          OwnerId: addAnnouncementResponse.id,
          Behaviour: 1,
          CreatedByPresenceId: this.myPresenceId,
          Content: {
            $t: "Soteria.Models.MarkdownChatEntry, Soteria.Models",
            $v: {
              Text: vm.form.message,
            },
          },
        };
      }

      timelines
        .postTimelineEntry(tenantId, timelineMessage)
        .then(() => {
          vm.confirmAnnouncement(tenantId, addAnnouncementResponse.id);
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error creating timeline entry");
        });
    },

    confirmAnnouncement(tenantId, announcementId) {
      let vm = this;
      let confirmAnnouncementRequest = {
        AnnouncementId: announcementId,
        Members: null,
        ManagedChats: [this.selectedGroupId],
        Admins: null,
      };
      announcements
        .confirmAnnouncement(tenantId, confirmAnnouncementRequest)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Announcement created successfully");
          // vm.$router.push(
          //   "/senso/announcements/" + tenantId + "/" + announcementId
          // );
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error confirming announcement");
        });
    },

    stopLockdown() {
      this.lockdownStarted = false;
      this.lockdownStartedAt = null;
      this.form = {
        title: "",
        message: "",
      };
    },

    reloadAnnouncementStuff() {
      this.announcementLoadGroups();
      this.announcementLoadGroupMembers();
      this.announcementLoadPresets();
    },

    openSelectGuidanceModal() {
      this.selectGuidanceModalVisible = true;
    },

    closeSelectGuidanceModal() {
      this.selectGuidanceModalVisible = false;
    },

    announcementLoadGroups() {
      let vm = this;
      vm.isLoadingGroups = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      chats
        .getManagedChatsForOrg(tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.isLoadingGroups = false;
          vm.groups = r.data;
          vm.selectFirstGroup();
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingGroups = false;
          // vm.$message.error("Error loading groups");
        });
    },

    announcementLoadGroupMembers() {
      let vm = this;
      vm.isLoadingGroupMembers = true;

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      chats
        .getGroupMembersForChat(tenantId, this.selectedGroupId)
        .then((r) => {
          vm.isLoadingGroupMembers = false;
          vm.groupMembers = r.data;
        })
        .catch((e) => {
          // vm.$message.error("Error loading group members");
          console.log(e);
        });
    },

    announcementLoadPresets() {
      let vm = this;
      vm.isLoadingAnnouncementPresets = true;
      vm.announcementPresets = [];

      let tenantId = organisationHelpers.getOrganisationTenantId(
        this.selectedOrganisation
      );

      announcements
        .getAnnouncementPresetsForOrg(tenantId, this.selectedOrganisation.id)
        .then((r) => {
          vm.isLoadingAnnouncementPresets = false;
          vm.announcementPresets = r.data;
          vm.selectFirstAnnouncementType();
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingAnnouncementPresets = false;
          // vm.$message.error("Error loading announcement presets");
        });
    },

    selectFirstAnnouncementType() {
      if (this.announcementPresets.length && !this.announcementTypeId) {
        let announcementType = _.first(this.announcementPresets);
        this.announcementTypeId = announcementType
          ? announcementType["id"]
          : null;
      }
    },
  },
};
</script>

<style lang="scss">
.sims-lockdowns {
  padding-bottom: 20px;

  .flex {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      flex-grow: 1;
      padding-left: 15px;
      display: flex;
      align-content: center;
      align-items: center;
    }
  }

  .ant-descriptions-item-label {
    max-width: 400px !important;
    width: 400px !important;
  }

  .card {
    margin-bottom: 20px;
  }
}
</style>